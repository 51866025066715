<script setup>
  import { computed, onMounted, ref, watch } from 'vue'
  import { formatDate } from '../../utils/format_offer.js';
  import { BLink, BTable, BFormCheckbox, BPagination, BImg, BToast,
           BDropdown, BDropdownItemButton, BOffcanvas, BCollapse, BButton } from 'bootstrap-vue-next';
  import { usePublicationsStore } from '../../stores/PublicationStore.js';
  import { useTableColumnStore } from '../../stores/TableColumnStore.js';
  import imagePlaceHolder from '../../components/ui/assets/placeholder-image-vertical.png';
  import 'bootstrap-icons/font/bootstrap-icons.css'
  import { useItemStore } from '../../stores/ItemStore.js';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';
  import Sorter from '../catalog_publication_fields/Sorter.vue';
  import SorterMenu from '../catalog_publication_fields/SorterMenu.vue';
  import IsbnFormTags from './IsbnFormTags.vue';

  const { locale } = useI18n();
  const imagePlaceHolderUrl = (event) => {
    event.target.src = imagePlaceHolder;
  };
  const { t } = useI18n();
  const i18n_scope = 'components.catalog_publication.item_actions';
  const publicationsStore = usePublicationsStore();
  const { getColumnsCanBeHidden } = useTableColumnStore();
  const itemStore = useItemStore();
  const { menuActions: menuActions, loading: loadingMenuActions, error: menuActionsError } = storeToRefs(itemStore);
  const publicationItems = computed(() => {
    return publicationsStore.publications.data;
  });

  const publicationIncluded = computed(() => {
    return publicationsStore.publications.included;
  });

  const aggregations = computed(() => {
    return publicationsStore.publications.meta.aggregations;
  });

  const renderTitle = (title, subtitle) => {
    if(subtitle) {
      return `${title} | ${subtitle}`;
    }
    return title;
  }

  const findIncluded = (type, id) => {
    return publicationIncluded.value.find(item => item.type === type && item.id === id);
  }

  const showAuthors = (authors) => {
    if(authors) {
      return authors.map(author => {
        const authorData = findIncluded(author.type, author.id);
        return authorData.attributes.full_name;
      }).sort().join(', ');
    }
    return '';
  }

  const showLists = (lists, type = 'list') => {
    if(lists) {
      const dataList = lists.map(list => {
        const item = findIncluded(list.type, list.id);
        if(type === 'serie' && !item.attributes.series) { return ; }

        return item.attributes.name;
      })
      return dataList.filter(Boolean).join(', ');
    }
    return '';
  }

  const showCategories = (categories) => {
    if(categories) {
      return categories.map(category => {
        const categoryData = findIncluded(category.type, category.id);
        return categoryData.attributes.tree[locale.value];
      });
    };
    return '';
  }

  const showInfluences = (influences) => {
    if(influences) {
      return influences.map(influence => {
        const influenceData = findIncluded(influence.type, influence.id);
        return influenceData.attributes.tree.map(item => item.name).join(' > ');
      });
    };
    return '';
  }

  const perPage = computed(() => {
    return publicationsStore.publications.meta.pagination.per_page;
  });
  const totalPages = computed(() => {
    return publicationsStore.publications.meta.pagination.total_pages;
  });
  const totalRows = computed(() => {
    return publicationsStore.publications.meta.pagination.total_count;
  });

  const CurrentPage = computed ({
    get: () => {
      return publicationsStore.publications.meta.pagination.current_page;
    },
    set: (value) => {
      handlePage(value);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });


  const handlePage = async (page) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', page);
    await publicationsStore.fetchPublications(params);
    loadContextMenu();
  }

  const { loading } = storeToRefs(publicationsStore);

  const selectedItems = ref([]);
  const allSelected = ref(false);
  const hasDebug = ref(false);

  const selectAll = (value) => {
    selectedItems.value = value ? publicationItems.value.map(item => item.id) : [];
    allSelected.value = value;
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const resultOfItems = computed(() => {
    if(publicationItems.value.length > 0) {
      const firstItem = (parseInt(CurrentPage.value) - 1) * parseInt(perPage.value) + 1;
      const lastItem = CurrentPage.value * perPage.value;
      if(lastItem > totalRows.value) {
        return `${firstItem} - ${totalRows.value} of ${numberWithCommas(totalRows.value)}`;
      }
      return `${firstItem} - ${lastItem} of ${numberWithCommas(totalRows.value)}`;
    }
    return 'No results';
  });

  const orderKeys = ref({
    publication: '', title: '', publisher: '', author: '',
    collection: '', category: '', influence: ''
  });

  const toogleOrder = (key = 'title', order = 'asc') => {
    orderKeys.value[key] = order;
    handleSortby(`${key}_${orderKeys.value[key]}`);
    Object.entries(orderKeys.value).forEach(([k, v]) => {
      if(k !== key) {
        orderKeys.value[k] = '';
      }
    });
  }

  const disabledLink = computed(() => {
    return selectedItems.value.length === 0;
  });

  const queryParams = computed(() => {
    const params = new URLSearchParams(publicationsStore.queryParams);
    const arrayQuery = [];
    params.forEach((value, key) => {
      arrayQuery.push(`<b>${key}</b>= ${value};`);
    });
    return arrayQuery.join(' ');
  });

  const showQueryOpened = ref(false);

  const ColumnItems  = computed(() => {
    return publicationItems.value.map(item => {
      const publication = item.attributes;
      const cover = publication.cover;
      const authors = showAuthors(item.relationships.authors.data);
      const categories = showCategories(item.relationships.categories.data);
      const influences = showInfluences(item.relationships.influences.data);
      const lists = showLists(item.relationships.lists.data, 'list');
      const series = showLists(item.relationships.lists.data, 'serie');
      const publication_date = publication.release_date;
      return {
        id: item.id,
        cover: cover,
        publication: publication_date,
        title: renderTitle(publication.title, publication.subtitle),
        author: authors,
        publisher: publication.publisher,
        collection: publication.collection,
        category: categories,
        influence: influences,
        isbn: publication.isbn,
        last_update: publication.updated_at,
        book_format: publication.book_format,
        lists: lists,
        series: series,
        from_quebec: publication.from_quebec ? 'Yes' : 'No',

      }
    });
  });

  const { 
    columns, 
    activeColumns,
    inactiveColumns, 
    orderColumns
  } = useTableColumnStore();
  const tableStore = useTableColumnStore();

  const columnFields = computed(() => {
    return activeColumns.map((column) => {
      const rawColumn = columns.find(col => col.key === column);
      if(!['id', 'cover'].includes(rawColumn.key)) {
        rawColumn['class'] = 'table-column';
      }
      return rawColumn;
    });
  });

  const sortBy = ref([]);
  const openDropdownActions = ref(false);
  const showChangePositionElement = ref(false);

  const getGroupActionsUrl = (item) => {
    if(item.path === '') {
      item.fnc();
      return;
    }
    const selectedItemIds = selectedItems.value.join(',');
    if (item.url === '') { return ''; }

    const params = new URLSearchParams(publicationsStore.queryParams);
    const url = `/${item.path}?${params.toString()}&ids=${selectedItemIds}`;
    
    window.location.href = url;
  };
  

  const handleSortby = (key, order) => {
    const params = new URLSearchParams(window.location.search);
    if(typeof order === 'undefined') {
      params.delete('sort');
    } else {
      params.set('sort', `${key}_${order}`);
    }
    console.log('params', params.toString());
    publicationsStore.fetchPublications(params);
  };

  const setSortBy = (sort) => {
    const { key, order } = sort;
    handleSortby(key, order);
  };

  const applyColumnsOrderAndVisibility = () => {
    tableStore.applyOrderAndVisibility('columns');
    showChangePositionElement.value = false;
  };

  const showMenu = ref(false);
  const menuStyle = ref({
    top: '0px',
    left: '0px'
  });
  const hideMenu = () => {
    showMenu.value = false;
  };

  const handleContextMenu = (event,id) => {
    itemStore.fetchMenuActions(id);
    event.preventDefault();
    const { pageX, pageY } = event;

    menuStyle.value = {
      top: `${pageY * 1.23}px`,
      left: `${pageX * 1.23}px`
    };

    showMenu.value = true;
    window.addEventListener('click', hideMenu, { once: true });
  }

  const loadContextMenu = () => {
    document.querySelectorAll('.table-tr').forEach((item) => {
      let id = item.querySelector('.item-id');
      if(!id) { return; }
      id = id.getAttribute('data-id');
      item.addEventListener('contextmenu', event => {
        handleContextMenu(event, id);
      });
    });;
  };

  const showToast = ref(false);

  const postResync = async (action) => {
    await fetch(`${action.path}.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    });
    showToast.value = true;
    setTimeout(() => {
      showToast.value = false;
    }, 3000);
  };

  watch(loading, (value) => {
    if(!value) {
      setTimeout(() => {
        loadContextMenu();
      }, 500);
    };
  });

  const dropDownActions = [
    {
      group_name: '',
      id: 1,
      items: [
        {
          title: t(`${i18n_scope}.dropdown_export_results`),
          path: '/',
          method: 'get'
        },
        {
          title: t(`${i18n_scope}.dropdown_inject_list`),
          path: '',
          fnc: () => openedIsbnForm.value = true
        }
      ]
    },
    {
      group_name: 'Group actions',
      id: 2,
      items: [
        {
          title: t(`${i18n_scope}.dropdown_add_selection_to_list`),
          path: 'admin/books/add_to_list_batch',
          method: 'get'
        },
        {
          title: t(`${i18n_scope}.dropdown_apply_metadata_to_selection`),
          path: 'admin/books/edit_batch',
          method: 'get'
        },
        {
          title: t(`${i18n_scope}.dropdown_synchronize_selection`),
          path: 'admin/books/synchronize_batch',
          method: 'get'
        }
      ]
    }
  ];

  const showChangePositionAction = (value) => {
    showChangePositionElement.value = value;
    applyColumnsOrderAndVisibility();
  };

  const togglePositionMenu = () => {
    showChangePositionElement.value = true;
  };

  const openedIsbnForm = ref(false);
  const openedIsbnCollapse = ref(false);

  const copyIsbns = (isbns) => {
    navigator.clipboard.writeText(isbns.join(','));
  }

  onMounted( async () => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', CurrentPage.value);
    params.set('territory', 'default');
    if(params.has('sort')) {
      const [key, order] = params.get('sort').split('_');
      sortBy.value = [{ key, order }];
    };
    if (params.has('debug') && params.get('debug') === 'true') {
      hasDebug.value = true;
    }
    await publicationsStore.fetchPublications(params);
  });
</script>

<template>
  <BOffcanvas v-model="openedIsbnForm" teleport-disabled="true" placement="top" class="custom-offcanvas">
     <div class="d-flex justify-content-center align-items-center">
        <div class="container">
          <IsbnFormTags @sent="openedIsbnForm= false" />
        </div>
      </div>
  </BOffcanvas>
  <BToast v-model="showToast" variant="info" class="toast-container position-fixed mp-3 top-0 end-0">
    {{ t('components.catalog_publication.item_actions.resynced') }}
  </BToast>
  <div v-if="showMenu" :style="menuStyle" class="custom-menu">
    <ul v-if="!loadingMenuActions && !menuActionsError">
      <li v-for="action in menuActions" :key="action.id">
        <BLink :href="action.path" target="_blank" v-if="action.method === 'get'">
          {{ action.title }}
        </BLink>
        <BLink @click.prevent="postResync(action)" v-else>
          {{ action.title }}
        </BLink>
      </li>
    </ul>
    <div v-if="loadingMenuActions" class="text-center text-primary p-3">
      <strong>{{ t('components.catalog_publication.item_actions.loading') }}</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <div class="search-result w-100">
        <p>
          <b>{{t('components.catalog_publication.item_actions.search_results')}}: </b>
            <span>{{ numberWithCommas(totalRows) }}</span> {{t('components.catalog_publication.item_actions.items')}}
          <span>
            <BLink @click.prevent="showQueryOpened = !showQueryOpened">
              <span>{{t('components.catalog_publication.item_actions.show_full_query')}}</span>
              <i class="bi" :class="{ 'bi-caret-up-fill': showQueryOpened, 'bi-caret-down-fill': !showQueryOpened}"></i>
            </BLink>
            <div v-html="queryParams" v-show="showQueryOpened">
            </div>
            <br>
            <pre v-show="showQueryOpened && hasDebug">{{ publicationsStore.publications.meta.es_query }}</pre>
          </span>
        </p>
      </div>
      <p>{{t('components.catalog_publication.item_actions.page')}}: {{ CurrentPage }} {{t('components.catalog_publication.item_actions.out_of')}} {{ numberWithCommas(totalPages) }}</p>
      <p>{{t('components.catalog_publication.item_actions.results')}}: {{ resultOfItems }}</p>
      <p>
        {{ selectedItems.length  }} {{t('components.catalog_publication.item_actions.lines_selected')}} -
        <BLink @click.prevent="selectAll(false)" :class="{ 'link-disable': disabledLink }">
          {{t('components.catalog_publication.item_actions.clear_selection')}}
        </BLink>
      </p>
      <div class="row" v-if="aggregations">
        <h3>
          <span class="title-total">
            {{ t('components.catalog_publication.isbn_results.result_found', {of: aggregations.isbn_results.total_found, from: aggregations.isbn_results.total}) }}
          </span>
        </h3>
        <div class="box-isbns" v-if="aggregations.isbn_results.not_found.length > 0">
          <BButton variant="link" @click="openedIsbnCollapse = !openedIsbnCollapse" class="m-1 p-0 btn-link" size="lg">
            {{ t('components.catalog_publication.isbn_results.items_not_found', { items: aggregations.isbn_results.total_not_found }) }}
            <i class="bi" :class=" openedIsbnCollapse ? 'bi-arrow-up-circle' : 'bi-arrow-right-circle'"></i>
          </BButton>
          <BCollapse v-model="openedIsbnCollapse">
            <BButton @click="copyIsbns(aggregations.isbn_results.not_found)" variant="primary" size="sm">
              <i class="bi bi-copy"></i> {{ t('components.catalog_publication.isbn_results.copy_not_found_isbns') }}
            </BButton>
            <ul>
              <li v-for="isbn in aggregations.isbn_results.not_found" :key="isbn">
                {{ isbn }}
              </li>
            </ul>
          </BCollapse>
        </div>
      </div>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-end">
      <SorterMenu
        id="sorterMenuColumns"
        @showChangePositionAction="showChangePositionAction"
        @togglePositionMenu="togglePositionMenu"
        :columns="columns"
        :activeColumns="activeColumns"
        :inactiveColumns="inactiveColumns"
        :orderColumns="orderColumns"
        :title="t('components.catalog_publication.filters.sorter_change_columns_title')"
        :getCanBeHidden="getColumnsCanBeHidden"
        :type="'columns'"
      />

      <div class="dropdown-actions pl-5">
        <BDropdown v-model="openDropdownActions" variant="primary" :end="true" :no-shift="true" no-caret>
          <template #button-content>Actions <i class="bi bi-list"></i></template>
          <template v-for="group in dropDownActions" :key="group.id">
            <template v-if="group.group_name">
              <BDropdownItemButton disabled><b>{{ group.group_name }}</b></BDropdownItemButton>
            </template>
            <BDropdownItemButton v-for="item in group.items" :key="item.title" @click.prevent="getGroupActionsUrl(item)">
              {{ item.title }}
            </BDropdownItemButton>
          </template>
        </BDropdown>
      </div>
    </div>
  </div>
  <Sorter
    v-if="showChangePositionElement"
    @showChangePositionAction="showChangePositionAction"
    :columns="columns"
    :activeColumns="activeColumns"
    :inactiveColumns="inactiveColumns"
    :orderColumns="orderColumns"
    :type="'columns'"
  />
  <div class="row mb-5 mt-5 scroll-horizontal">
    <BTable
      :striped="true"
      responsive="sm"
      v-model:sort-by="sortBy"
      :items="ColumnItems"
      :sort-internal="true"
      :fields="columnFields"
      :busy="loading"
      :onSorted="setSortBy"
      tbodyTrClass="table-tr"
      :bordered="true"
      theadTrClass="table-header-tr"
      >
      <template v-slot:head(id)>
        <BFormCheckbox
          v-model="allSelected"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
          @change="selectAll(allSelected)"
        />
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(title)="{ item }">
        <a class="link-default" target="_blank" :href="`/item/${item.id}`">
          {{ item.title }}
        </a>
      </template>
      <template #cell(id)="{ item }">
        <span class="item-id hidden" :data-id="item.id">{{ item.id }}</span>
        <BFormCheckbox
          v-model="selectedItems"
          name="checkbox-item"
          :value="item.id"
        />
      </template>
      <template #cell(cover)="{ item }">
        <BImg class="cover" :src="item.cover" @error="imagePlaceHolderUrl" :lazy="true" v-if="item.cover" />
        <BImg class="cover" :src="imagePlaceHolder" @error="imagePlaceHolderUrl" :lazy="true" v-else />
      </template>

      <template #cell(influence)="{ item }">
        <p v-for="influence in item.influence">{{ influence }}</p>
      </template>

      <template #cell(category)="{ item }">
        <p v-for="category in item.category">{{ category }}</p>
      </template>

      <template #cell(publication)="{ item }">
        {{ formatDate(new Date(item.publication)) }}
      </template>

      <template #cell(last_updated)="{ item }">
        {{ formatDate(new Date(item.last_update)) }}
      </template>
    </BTable>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center" v-if="!loading">
      <BPagination
        v-model="CurrentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .cover {
    max-width: 150px;
    display: block;
  }
  :deep(.table) {
    margin: 0 !important;
  }
  .link-disable {
    color: #6c757d;
    pointer-events: none;
    text-decoration: none;
  }
  .order-columns {
    border: 3px solid #ccc;
    padding: 20px;
    margin: 20px 0;
    position: relative;
    button.close {
      background: transparent;
      border: none;
      color: #777;
      &:hover {
        color: #000;
      }
      i {
        font-size: 2em;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .order-confirm-or-cancel-buttons {
      button {
        margin: 0 10px;
      }
    }
    :deep(.columns-dropdown .dropdown-menu ul li) {
      padding: 10px !important;
    }
    ul {
      margin: 30px;
      padding: 0;
      list-style: none;
      li {
        border-radius: 5px;
        padding: 5px 15px;
        font-weight: semi-bold;
        font-size: 1.1em;
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        .icon-eye {
          margin-right: 10px;
          position: relative;
          span {
            position: absolute;
            height: 23px;
            width: 2px;
            rotate: 45deg;
            left: 7px;
            display: block;
            opacity: 0.8;
            background-color: #3c93df;
          }
        }
        button {
          border: none;
          cursor: pointer;
        }
      }
    }
    .inactive-columns {
      li {
        color: #3c93df;
        border: 1px solid #6baee9;
        .icon-eye {
          margin-right: 10px;
        }
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .positions {
      li {
        background-color: #3c93df;
        color: #fff;
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
        .icon-eye {
          span {
            display: none;
          }
          &:hover {
            span {
              display: block;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  :deep(.button-columns-change) {
    color: #3c93df !important;
    border-color: #6baee9 !important;
    background-color: #fff !important;
    &:hover {
      color: #fff !important;
      background-color: #3c93df !important;
    }
  }
  :deep() {
    .table-responsive-sm {
      display: block;
      overflow-x: auto;
      tbody, thead {
        .table-column {
          min-width: 190px;
          max-width: 250px;
        }
        th, td {
          &:first-child, &:nth-child(2), &:nth-child(3) {
            position: sticky;
            z-index: 3;
          }
          &:first-child {
            left: 0;
          }
          &:nth-child(2) {
            left: 30px;
          }
          &:nth-child(3) {
            left: 199px;
          }
        }
      }
      tbody {
        tr {
          max-height: 242px;
        }
      }
    }
  }
  .content-text {
    display: block;
  }
  .link-default {
    text-decoration: none;
    &:hover {
      color: #155995;
    }
  }
  .custom-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a {
          display: block;
          padding: 8px 12px;
          cursor: pointer;
          color: #333;
          text-decoration: none;
          &:hover {
            background-color: #f8f9fa;
          }
        }
      }
    }
  }
  .dropdown-actions {
    margin-left: 30px;
    :deep(.disabled) {
      color: #000 !important;
    }
  }
  :deep(.table-header-tr) {
    th {
      background-color: #fff;
      i {
        position: relative;
        z-index: 1;
      }
    }
  }
  .box-isbns {
    margin: 20px 0;
    .btn-link {
      color: #000;
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0 0 0;
      li {
        padding: 5px;
      }
    }
  }
</style>
