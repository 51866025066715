import { defineStore } from "pinia";

export const usePublicationsStore = defineStore("PublicationsStore", {
  state: () => ({
    publications: {
      data: [],
      included: [],
      meta: {
        pagination: {
          current_page: 1,
          total_pages: 1,
          total_count: 0,
          per_page: 25
        },
        aggregations: null
      }
    },
    loading: false,
    queryParams: ''
  }),
  actions: {
    async fetchPublications(params = {}, from_filters = false) {
      let isbnQuery = '';
      if(params.isbns) {
        isbnQuery = params.isbns.map(isbn => `isbns[]=${isbn}`).join('&');
        delete params.isbns;
      }
      if (from_filters) {
        params = this.cleanUpFields(params);
        if(!params.territory_state) params.territory_state = 'default';
      }
      this.loading = true;
      this.queryParams = new URLSearchParams(params).toString();

      if(from_filters) {
        params = this.cleanUpFields(params, 'All');
      }
      let query = new URLSearchParams(params).toString();
      if(isbnQuery) {
        query = query ? `${query}&${isbnQuery}` : isbnQuery;
      }

      try {
        if(query) {
          window.history.replaceState({}, '', `?${query}`);
        } else {
          window.history.replaceState({}, '', '');
        }
        const response = await this.$axios.get(`/api/v1/catalog/store_items.json?${query}`);
        this.publications = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      this.loading = false;
    },

    cleanUpFields(params, condition = null) {
      let cleanParams = {};
      if (Object.keys(params).length !== 0) {
        for (let key in params) {
          if (params[key] !== condition && params[key] !== '') {
            if (Array.isArray(params[key])) {
              if (params[key].length > 0) {
                const value = params[key][Object.keys(params[key])[0]];
                if (value !== 'All') {
                  cleanParams[key] = value;
                }
              }
            } else {
              cleanParams[key] = params[key];
            }
          }
        }
      }

      return cleanParams;
    },
  }
});
