import { useI18n } from "vue-i18n";

export const formatOfferDetailsConcurrentUsers = (concurrent_users) => {
  const { t } = useI18n();
  if (concurrent_users == 0 || concurrent_users == 999999) {
    return t('cart.loans_concurrent_users.unlimited');
  }
  else if (concurrent_users == 1) {
    return t('cart.loans_concurrent_users.one');
  }
  return t('cart.loans_concurrent_users.other', { count: concurrent_users })
}

export const formatOfferDetailsLifetime = (lifetime, inDays = false) => {
  const { t } = useI18n();
  if (lifetime == 0) {
    return t('cart.loans_lifetime.unlimited');
  }
  else {
    if (!inDays && Math.floor(lifetime / 365) == 0) {
      inDays = true
    }
    if (inDays) {
      if (lifetime == 1) {
        return t('cart.datetime.distance_in_words.x_days.one')
      }
      else {
        return t('cart.datetime.distance_in_words.x_days.other', { count: lifetime })
      }
    }
    else {
      let years = Math.ceil(lifetime / 365);
      let days = lifetime % 365;
      if (years == 1) {
        return t('cart.datetime.distance_in_words.x_years.one')
      }
      else if (years > 1) {
        if (days == 0) {
          return t('cart.datetime.distance_in_words.x_years.other', { count: years })
        }
        else {
          return t('cart.datetime.distance_in_words.x_years_and_days', { years: years, days: days })
        }
      }
      else {
        return t('cart.datetime.distance_in_words.x_days.other', { count: days })
      }
    }
  }
}

export const formatDate = (date) => {
  const { locale } = useI18n();
  return date.toLocaleDateString(findLocale(locale));
}


export const formatLoans = (loans) => {
  const { t } = useI18n();
  if (loans == 0 || loans == 999999) {
    return t('cart.loans.unlimited');
  }
  else if(loans == 1) {
    return t('cart.loans.loan', 1)
  }
  else {
    return t('cart.loans.loan', loans, { count: loans })
  }
}

export const bookFormat = (format, fixed_layout = false) => {
  const { t } = useI18n();
  if (format == 'epub' && fixed_layout){
    return Object.assign(format, { format: 'epub_fixed_layout', label: t('book_format.name.epub_fixed_layout') });
  }
  return Object.assign(format, { format: format, label: format.toUpperCase() });
}

export const showPriceWithCurrency = (currency, price) => {
  const { t } = useI18n();
  if (currency && !isNaN(price)) {
    const amount = parseFloat(price).toFixed(2)
    return t(`cart.carts.currencies.${currency?.toUpperCase()}.formatted_text`, { amount: amount })
  }
  return ''
}
export const formatDateTime = (datetime) => {
  const { locale } = useI18n();
  const date = new Date(datetime);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleDateString(findLocale(locale), options);
}


const findLocale = (locale) => {
  const locales = {
    'en': 'en-US',
    'fr': 'fr-FR',
    'es': 'es-ES',
    'it': 'it-IT',
    'pt': 'pt-PT'
  }
  return locales[locale.value] || 'en-US';
}
