import axios from 'axios';

const API_URL = window.location.origin

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 180000
});

const token = localStorage.getItem('apiToken');
if (token) {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default {
  get(endpoint) {
    
    return apiClient.get(endpoint);
  },
  post(endpoint, data) {
    return apiClient.post(endpoint, data);
  },
  put(endpoint, data) {
    return apiClient.put(endpoint, data);
  },
  delete(endpoint) {
    return apiClient.delete(endpoint);
  }
};
