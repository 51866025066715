import { defineStore } from "pinia";

const OFFER_CHUNK = 100;

export const useOfferStore = defineStore("OfferStore", {
  state: () => ({
    offers: [],
    offer: null,
    loading: false,
    loading_quantity: false,
    offer_purchases: null,
    operation_response: null
  }),
  getters: {
    offersSelected() {
      return this.offers.filter(offer => offer.checked);
    },
    notSelectedOffers() {
      return this.offers.filter(offer => !offer.checked);
    },
    cartOffersQuantity() {
      let quantity = 0;
      this.offers.forEach(item => {
        if (item.offer.quantity) { quantity += item.offer.quantity; }
      });
      return quantity;
    },
    offersUnavailable() {
      return this.offers.filter(item => item.offer.available == false);
    },
    offersNotFromDeMarque() {
      return this.offers.filter(item => !item.offer.from_de_marque);
    }
  },
  actions: {
    async fetchOfferAlreadyPurchased(cartId, offerId) {
      try {
        const response = await this.$axios.get(`/carts/${cartId}/offers/${offerId}/already_purchased.json`);
        if (response.status == 200) {
          this.offer_purchases = response.data
        }
      }
      catch (error){
        console.error('Error fetching data:', error);
      }
    },
    async reloadOffers(cartId) {
      const offerIds = this.offers.map(offer => offer.offer.id);
      await this.fetchOffers(cartId, offerIds);
    },
    async fetchOffers(cartId, offer_ids, update_items = false) {
      const promises = [];
      if (!update_items) { this.loading = true }
      try {
        for (let i = 0; i < offer_ids.length; i += OFFER_CHUNK) {
          const ids = offer_ids.slice(i, i + OFFER_CHUNK).join(',');
          promises.push(this.$axios.get(`/carts/${cartId}/offers/${ids}.json`));
        }
        const responses = await Promise.all(promises);
        const items = responses.map(response => response.data).flat().filter(item => item.publication);
        if (update_items) {
          items.forEach(cart_item => {
            const offerIndex = this.offers.findIndex(item => item.id === cart_item.id);
            if(typeof offerIndex == 'number') {
              this.offers.splice(offerIndex, 1, cart_item)
            }
          });
        }
        else {
          this.offers = items.sort((a, b) => a.id - b.id);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },
    async updateOperationOffers(cartId, offerId, kind = 'disable', newPrice = null) {
      console.log(offerId)
      console.log(kind)
      console.log(newPrice)
      try {
        const params = { operation: kind, new_price: newPrice }
        const response = await this.$axios.post(`/carts/${cartId}/offers/${offerId}/cleanup`, params);
        if (response.status === 200) {
          this.operation_response = response.data;
          const cartItem = this.offers.find(item => item.offer.id === offerId);
          switch (kind) {
            case 'disable':
              return cartItem.offer.available = false;
            case 'update_price':
              return cartItem.price.price_without_taxes = newPrice;
            case 'remove':
              return this.offers = this.offers.filter(item => item.offer.id !== offerId);
            case 'retry':
              return cartItem.status = 'added';
            default:
          }
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    incrementQuantity(OfferId) {
      const item = this.offers.find(offer => offer.id === OfferId);
      if (item) {
        item.offer.quantity++;
        this.updateOfferQuantity(item);
      }
    },
    decrementQuantity(OfferId) {
      const item = this.offers.find(offer => offer.id === OfferId);
      if (item) {
        if (item.offer.quantity > 0) {
          item.offer.quantity--;
          this.updateOfferQuantity(item);
        }
      }
    },
    updateQuantity(OfferId, quantity) {
      const item = this.offers.find(offer => offer.id === OfferId);
      if (item) {
        item.offer.quantity = quantity;
        const offer = {
          id: item.id,
          quantity: item.offer.quantity
        }
        if (quantity != '') {  this.updateOfferQuantity(item); }  
      }
    },
    async updateOfferQuantity(item) {
      this.loading_quantity = true;
      try {
        const data = {
          from_cart: true, items: [{ id: item.offer.id, quantity: item.offer.quantity }]
        }
        await this.$axios.put(`/carts/${item.cart_id}.json`, data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading_quantity = false;
    },
    toggleSelectAll(checked, refundMode = false) {
      const offers = refundMode ? this.offers.filter(item => item.offer.from_de_marque && item.status === 'validate') : this.offers;
      offers.forEach(offer => {
        offer.checked = checked;
      });
    },
    removeFromList(itemId) {
      this.offers = this.offers.filter(item => item.id !== itemId);
    },
    async deleteCheckedOffersByCartId(id) {
      try {
        const offer_ids = this.offers.filter(offer => offer.checked).map(offer => offer.offer.id);
        if (offer_ids) {
          const ids = offer_ids.join(',');
          const response = await this.$axios.delete(`/carts/${id}/items/${ids}.json`);
          if (response.status === 204) {
            this.offers = this.offers.filter(offer => !offer.checked);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    offersOrder(by = 'title') {
      if (by === 'title') {
        return this.offers.sort((a, b) => a.publication.title.localeCompare(b.publication.title));
      }
      else if (by === 'release_date') {
        return this.offers.sort((a, b) => new Date(b.publication.published_at) - new Date(a.publication.published_at));
      }
      else if (by === 'format') {
        return this.offers.sort((a, b) => a.offer.format.localeCompare(b.offer.format));
      }
      else if(by === 'author') {
        return this.offers.sort((a, b) => {
          if (a.publication.authors.length === 0) { return 1; }
          if (b.publication.authors.length === 0) { return -1; }

          const authorA = a.publication.authors[0];
          const authorB = b.publication.authors[0];
          return authorA.localeCompare(authorB);
        });
      }
      else {
        return this.offers.sort((a, b) => a.id - b.id);
      }
    }
  }
});
