<script setup>
  import { defineProps, defineEmits, ref, defineExpose } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const emit = defineEmits([
    'clearField', 
    'dateChange'
  ]);
  const props = defineProps({
    type: {
      type: String,
      default: ''
    }
  });

  const clearDates = () => {
    start.value = null;
    end.value = null;
  }

  defineExpose({
    clearDates
  })

  const start = ref(null); 
  const end = ref(null);

  const clearField = (field, inputId, isDate) => {
    if (inputId === `${props.type}_date_start`) start.value = null;
    if (inputId === `${props.type}_date_end`) end.value = null;
    emit('clearField', field, inputId, isDate);
  }

  const dateChange = (event) => {
    if (start.value && end.value && start.value > end.value) start.value = end.value;
    emit('dateChange', event);
  }
  
</script>

<template>
  <label class="form-label" v-if="type === 'publication'">
    {{t('components.catalog_publication.filters.publication_date')}}
  </label>
  <label class="form-label" v-if="type === 'offer_creation'">
    {{t('components.catalog_publication.filters.catalog_date')}}
  </label>
  <div class="row">
    <div class="col">
      <div class="input-group">
        <input
          type="date"
          :id="`${type}_date_start`"
          class="form-control"
          v-on:input="dateChange"
          v-model="start"
          :data-type="type" />
        <span
          class="input-group-text"
          @click="clearField(`${type}_date_start`, `${type}_date_start`, false)"
        >x</span>
      </div>
    </div>
    <div class="col">
      <div class="input-group">
        <input
          type="date"
          :id="`${type}_date_end`"
          class="form-control"
          v-on:input="dateChange"
          v-model="end"
          :data-type="type" />
        <span
          class="input-group-text"
          @click="clearField(`${type}_date_end`, `${type}_date_end`, false)"
        >x</span>
      </div>
    </div>
  </div>
</template>
