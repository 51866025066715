import { defineStore } from "pinia";

export const useCatalogPublicationInfluencesStore = defineStore("CatalogPublicationInfluencesStore", {
  state: () => ({
    data: null,
    loading: false,
  }),
  actions: {
    async fetchCatalogPublicationInfluences() {
      this.loading = true;
      try {
        const response = await this.$axios.get('/api/v1/catalog/influences.json');
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    }
  }
});
